.vehicle-details {
  max-width: 540px;
  width: 100%;
  padding: 15px;
  padding-bottom: 80px;

  .v-type-img {
    width: 170px;
  }

  .modal-close {
    text-align: right;
    z-index: 1;
    position: absolute;
    top: 1px;
    right: 1px;
    img {
      width: 35px;
      cursor: pointer;
    }
  }

  .v-card {
    padding-top: 20px;
    border: none;

    @media (min-width: 576px) {
      padding-top: 0;
    }

    .v-main-img {
      padding: 0;
    }
  }

  .photos {
    margin-top: 0px;

    img {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .close {
    border: 3px solid #000;
    font-size: 20px;
    display: block;
    padding: 8px 0;
    text-align: center;
    width: calc(100% - 3px);
    position: fixed;
    bottom: 0;
    background: #fff;
    color: #000;
    font-weight: 500;
    max-width: 540px;
    opacity: 1;
    background: #e2e2e2;
    @media (max-width: 576px) {
      left: 3px;
    }
    &:hover {
      opacity: 1 !important;
    }
  }

  .comms {
    margin-top: -25px;

    span {
      @media (min-width: 576px) {
        display: inline-block;
      }
    }
    button {
      border: none;
      background: none;
    }

    img {
      width: 50px;
      margin-left: 10px;
    }
  }
}
