.home {
  .banner-wrapper {
    position: relative;

    .user {
      position: absolute;
      right: 21px;
      top: 3px;
      img {
        width: 20px;
        margin-left: 5px;
      }
    }

    .banner {
      width: 100%;
    }
  }
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: #737577;
    text-align: center;
    padding: 12px 0;
  }

  .v-type {
    margin-bottom: 10px;
    &:nth-child(even) {
      padding-left: 5px;
    }
    &:nth-child(odd) {
      padding-right: 5px;
    }
    img {
      width: 100%;
    }
  }

  .v-link {
    display: block;
    &:active {
      box-shadow: 0px 0px 5px #2f7fec;
    }
  }

  .see-more {
    cursor: pointer;
    width: 100%;
  }
}
