.result-item {
  border: 1px solid #5c5c5c;
  margin-bottom: 8px;
  margin-left: 0;
  margin-right: 0;
  padding-top: 15px;
  padding-bottom: 15px;

  .mobile-only {
    padding-top: 6px;
    left: -2px;
    position: relative;
  }

  &.item-clickable {
    cursor: pointer;
  }

  .driver-image {
    height: 50px;
    width: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    display: inline-block;
  }

  .driver-name-avatar-icon {
    width: 17px;
    margin-top: -5px;
    margin-right: 3px;
  }

  .driver-areas-icon {
    width: 17px;
  }

  .v-main-img {
    padding-right: 0;
    img {
      width: 100%;
    }
  }

  .driver-info {
    text-align: left;
    margin-top: 15px;
    @media (min-width: 576px) {
      margin-top: 0;
      text-align: center;
    }
  }

  .v-info {
    padding-right: 6px;
  }

  .rating {
    display: inline-block;
  }

  .desktop-only {
    display: none;
    @media (min-width: 576px) {
      display: block;
    }
  }

  .mobile-only {
    @media (min-width: 576px) {
      display: none;
    }
  }
}
