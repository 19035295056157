.header {
  a {
    width: 80%;
    display: block;
    margin: auto;
    padding-bottom: 15px;
    @media (min-width: 576px) {
      width: 60%;
    }
    max-width: 280px;
  }
  .logo {
    width: 100%;
  }
  .divider {
    border-top: 1px solid #c4c4c4;
    height: 1px;
  }
}
