.search-results {
  margin-top: 10px;

  h1 {
    font-size: 18px;
    margin-top: 15px;
    color: #737577;
  }
  .user {
    img {
      width: 20px;
      margin-right: 5px;
    }
  }
  .v-type-img {
    width: 100%;
  }

  .header {
    margin-bottom: 15px;
  }

  .back-to-home-link {
    border: 2px solid #000;
    font-size: 20px;
    display: block;
    padding: 4px 0;
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #fff;
    color: #000;
    font-weight: 500;
    max-width: 540px;
    background: #e2e2e2;
  }
}

.custom-modal {
  max-width: 540px;
  @media (max-width: 576px) {
    margin: 5px;
  }
  .modal-content {
    border-radius: 0;
  }
}
